import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESOURCE_ELASTICSEARCH_DOCUMENT } from "../../../constants/appConstants";
import {
    deleteRecentSearchItem,
    setAutoCompleteQuery,
    toggleSuggestionsModal,
} from "../../../store/actions/Autocomplete";
import { setSearchQuery } from "../../../store/actions/Filters";

import Loader from "../loader";
import { LANGUAGE_EN_CODE } from "../../../../GlobalSearch/constants/appConstants";
import { setElasticSearchDocumentDependingOnURL } from "../../../../GlobalSearch/utils/helpers";
import { setSearchRequestComesFromPage } from "../../../../GlobalSearch/utils/helpers";
import PropTypes from "prop-types";

/**
 * Component to show Autocomplete suggestions
 *
 * @param {*} param0
 * @returns
 */
const Suggestions = ({ showRecent }) => {
    const currentLanguage = window.languageCode;

    const { results, query, loading, recentSearcheQueries, suggestionModalOpen } =
        useSelector((state) => state.autoComplete);
    const dispatch = useDispatch();
    const activeElasticSearch = setElasticSearchDocumentDependingOnURL();
    const searchRequestComesFromPage = setSearchRequestComesFromPage();
    const langCode = currentLanguage === 'en' ? 'en' : 'fr';
    const slug = currentLanguage === "en" ? "global-search" : "recherche-globale";
    /**
     * Close suggestion modal
     *
     * @param {*} query
     */
    const closeSuggestionModal = (query) => {
        dispatch(toggleSuggestionsModal(false));
        dispatch(setAutoCompleteQuery(query, RESOURCE_ELASTICSEARCH_DOCUMENT));
    };

    /**
     * Handle the click for search query in suggestions modal
     *
     * @param {*} suggestion
     */
    const handleQueryClick = (suggestion) => {
        dispatch(
            setSearchQuery(suggestion, () => closeSuggestionModal(suggestion))
        );
    };

    /**
     * Handle deleting recent search item
     *
     * @param {*} event
     * @param {*} index
     */
    const handleRecentSearchItemDelete = (event, index) => {
        event.stopPropagation();
        dispatch(deleteRecentSearchItem(index));
    };

    /**
     * Function to render highlighted text
     * We will highlight the query in the suggestion, but not the user
     *
     * @param {*} suggestion
     * @param {*} query
     * @returns
     * @memberof Suggestions
     */
    const renderHighlightedText = (suggestion, query) => {
        const parts = suggestion.split(new RegExp(`(${query})`, "gi"));
        return (
            <span>
        { parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
                <span key={ index }>{ part }</span>
            ) : (
                <em key={ index }>{ part }</em>
            )
        ) }
      </span>
        );
    };

    return (
        <>
            { (suggestionModalOpen || showRecent) && (
                <ul className="autocomplete-dropdown">
                    { showRecent && recentSearcheQueries.length > 0 && (
                        <li className="d-block mt-3">
                            <>
                                <h6>{ currentLanguage === "en" ? "Recent Searches" : "Recherches récentes" }</h6>
                                <ul className="recent-searches">
                                    { recentSearcheQueries.map((query, index) => (
                                        <>
                                            <li
                                                key={ query + index }
                                                className="recent-search-item"
                                            >
                                                <a
                                                    href={ `/${ langCode }/${ slug }?f=${ searchRequestComesFromPage }&tab=${ activeElasticSearch }&query=${ query }` }
                                                    onClick={ () => handleQueryClick(query) }
                                                    tabIndex="0"
                                                >
                                                    <img
                                                        src="/static/img/ic_duree.svg"
                                                        onClick={ (event) =>
                                                            handleRecentSearchItemDelete(event, index)
                                                        }
                                                    />
                                                    { query }
                                                </a>
                                                <img className="recent-search-item__remove"
                                                     src="/static/img/clear-recent.svg"
                                                     onClick={ (event) =>
                                                         handleRecentSearchItemDelete(event, index)
                                                     }
                                                />
                                            </li>
                                        </>
                                    )) }
                                </ul>
                            </>
                        </li>
                    ) }

                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center loader-container">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            {results.length > 0 ? (
                                <>
                                    {results.slice(0, 8).map((suggestion, index) => (
                                        <li key={index}>
                                            <a
                                                href={`/${langCode}/${slug}?f=${searchRequestComesFromPage}&tab=${activeElasticSearch}&query=${suggestion}`}
                                                className="search-link"
                                                onClick={() => handleQueryClick(suggestion)}
                                                tabIndex="0"
                                            >
                                                <i className="fas fa-search"></i>
                                                {renderHighlightedText(suggestion, query)}
                                            </a>
                                        </li>
                                    ))}
                                </>
                            ) : query.length > 0 && (
                                <li>
                                    <p className="search-link">
                                        <i className="fas fa-search"></i>
                                        <span>{currentLanguage === "en" ? "No results found" : "Aucun résultat trouvé"}</span>
                                    </p>
                                </li>
                            )}
                        </>
                    )}
                </ul>
            ) }
        </>
    );
};

Suggestions.propTypes = {
    showRecent: PropTypes.bool,
};
export default Suggestions;

