import Cookies from "js-cookie";
import {
    AND,
    DOCUMENT_SORTING_COLUMNS,
    GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
    OR,
    ORDER_ALPHABETICALLY,
    TAB_MAPPINGS, FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT, PRACTICAL_ADVICE_ELASTICSEARCH_DOCUMENT,
} from "../constants/appConstants";

/**
 *
 * @param key
 * @param initialValue
 * @returns {any}
 */
export const getLocalStorage = (key, initialValue) => {
    try {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : initialValue;
    } catch (e) {
        // if error, return initial value
        return initialValue;
    }
};

/**
 *
 * @param key
 * @param value
 */
export const setLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        // catch possible errors:
    }
};

/**
 * Get cookie by name
 * we can use it to get django csrf token too
 * @param {*} name
 * @returns
 */
export const getCookie = (csrftoken) => {
    return Cookies.get(csrftoken);
};

/*
 * Get current Django selected languagd
 * @param {*} defaultLanguage
 * @returns
 */
export const getCurrentLanguageCode = (defaultLanguage = "fr") => {
    const languageCode = document.querySelector("#language-code");

    return languageCode?.length > 0 ? languageCode.value : defaultLanguage;
};

/**
 * Transforme Filters into elasticsearch conditions
 *
 * @param {*} key
 * @param {*} filter
 * @returns
 */
export const transformFilter = (key, filter) => {
    if (!filter.value || !filter.value.length) {
        return [];
    }
    // So if key is not same as field, means we have nested condition
    const combinedField =
        key !== filter.field ? `${ key }.${ filter.field }` : filter.field;

    return filter.value.map((val, index) => ({
        condition: index === 0 ? AND : OR,
        field: combinedField,
        operator: filter.operator,
        value: val,
    }));
};

/**
 * Transforme Filters into Final elasticsearch payload
 * @param {*} state
 * @param {*} activeTab
 * @returns
 */
export const transformStateToPayload = (
    state,
    activeTab,
    searchQuery,
    page,
    sorting,
    currentLanguage
) => {
    const filterMappings = TAB_MAPPINGS[activeTab];

    if (!filterMappings) {
        throw new Error(`Unsupported tab: ${ activeTab }`);
    }

    const filters = Object.entries(filterMappings).map(([key, documentType]) => {
        const filterObj = state[key] || {};
        const transformedFilters = Object.entries(filterObj).flatMap(
            ([subKey, filter]) => transformFilter(subKey, filter)
        );


        const baseFilter = {
            [documentType]: {
                conditions: transformedFilters,
            },
        };
        baseFilter[documentType].boost = {
            [`title_${ currentLanguage }`]: 20000,
        };

        if (searchQuery === "") {
            if (sorting === ORDER_ALPHABETICALLY) {
                baseFilter[documentType].sort = {
                    [`${ DOCUMENT_SORTING_COLUMNS[documentType] }_${ currentLanguage }.sorting`]:
                        {
                            order: "asc",
                        },
                };
            }
        }

        return baseFilter;
    });

    for (let i = 0; i < filters.length; i++) {
        const key = Object.keys(filters[i])[0];
        if (filters[i][key].conditions.length === 0) {
            filters[i][key].conditions.push(
                {
                    "condition": "and",
                    "field": currentLanguage === "fr" ? "title_fr" : "title_en",
                    "operator": "is_not",
                    "value": "x"
                }
            );
        }
    }

    return {
        query: searchQuery,
        page: page,
        filters: filters,
    };
};

/**
 * Base filters
 */
export const baseFilters = [
    {
        [GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT]: {
            conditions: [],
        },
    },
    {
        [PRACTICAL_ADVICE_ELASTICSEARCH_DOCUMENT]: {
            conditions: [],
        },
    },
    {
        [FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT]: {
            conditions: [],
        },
    },
];

/**
 * Add debounce on actions
 *
 * @param {*} func
 * @param {*} wait
 * @returns
 */
export const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

/**
 * Get excerpt from long text
 *
 * @param {*} text
 * @param {*} maxLength
 * @returns
 */
export const getExcerpt = (text, maxLength = 100) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength - 3) + "...";
};

/**
 *
 * @param status
 * @param msg
 * @returns {string}
 */
export const setErrorMessage = (status, msg = "") => {
    const errorMessages = {
        404: "error.error_page_404",
        401: "error.error_page_401",
        403: "error.error_page_403",
        500: "Unexpected Error ):",
        422: "error.error_page_422",
        405: msg,
        409: msg,
        400: msg,
        402: msg,
    };

    return errorMessages[status] || "error.unexpected_error";
};
/**
 * Set Elasticsearch document depending on URL.
 * @returns {string} The document constant corresponding to the URL segment.
 */
export const setElasticSearchDocumentDependingOnURL = () => {
    const url = window.location.href;
    const documentMap = {
        // "practical-advice": PRACTICAL_ADVICE_ELASTICSEARCH_DOCUMENT,
        // "je-suis-aidant": PRACTICAL_ADVICE_ELASTICSEARCH_DOCUMENT,
        // "for-professionals": FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT,
        "i-am-a-professional": FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT,
        "je-suis-un-professionnel": FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT,
    };

    for (const key in documentMap) {
        // eslint-disable-next-line no-prototype-builtins
        if (documentMap.hasOwnProperty(key) && url.includes(key)) {
            return documentMap[key];
        }
    }

    // Default document if no other paths match
    return GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT;
};

export const setSearchRequestComesFromPage = () => {
    const url = window.location.href;
    const documentMap = {
        "practical-advice": 'standard',
        "je-suis-aidant": 'standard',
        "for-professionals": 'pro',
        "i-am-a-professional": 'pro',
        "je-suis-un-professionnel": 'pro',
    };

    for (const key in documentMap) {
        // eslint-disable-next-line no-prototype-builtins
        if (documentMap.hasOwnProperty(key) && url.includes(key)) {
            return documentMap[key];
        }
    }

    // Default document if no other paths match
    return 'standard';
};
