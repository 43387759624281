import Cookies from "js-cookie";
import {
    AGGREGATE_FILTERS,
    AND,
    DOCUMENTS_ELASTICSEARCH_DOCUMENT,
    DOCUMENT_SORTING_COLUMNS,
    GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
    NEWS_ELASTICSEARCH_DOCUMENT,
    OR,
    ORDER_ALPHABETICALLY,
    RESOURCE_ELASTICSEARCH_DOCUMENT,
    TAB_MAPPINGS,
} from "../constants/appConstants";

/**
 *
 * @param key
 * @param initialValue
 * @returns {any}
 */
export const getLocalStorage = (key, initialValue) => {
    try {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : initialValue;
    } catch (e) {
        // if error, return initial value
        return initialValue;
    }
};

/**
 *
 * @param key
 * @param value
 */
export const setLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        // catch possible errors:
    }
};

/**
 * Get cookie by name
 * we can use it to get django csrf token too
 * @param {*} name
 * @returns
 */
export const getCookie = (csrftoken) => {
    return Cookies.get(csrftoken);
};

/*
 * Get current Django selected languagd
 * @param {*} defaultLanguage
 * @returns
 */
export const getCurrentLanguageCode = (defaultLanguage = "fr") => {
    const languageCode = document.querySelector("#language-code");

    return languageCode?.length > 0 ? languageCode.value : defaultLanguage;
};

/**
 * Transforme Filters into elasticsearch conditions
 *
 * @param {*} key
 * @param {*} filter
 * @returns
 */
export const transformFilter = (key, filter) => {
    if (!filter.value || ( typeof(filter.value) !== "boolean" && !filter.value.length)) {
        return [];
    }
    // So if key is not same as field, means we have nested condition
    const combinedField =
        key !== filter.field ? `${ key }.${ filter.field }` : filter.field;
    if (filter.value === "*") {
        return [{
            condition: AND,
            field: combinedField,
            operator: filter.operator,
            value: filter.value,
        }];
    }else if(filter.value === true){
        return [{
            condition: AND,
            field: combinedField,
            operator: filter.operator,
            value: filter.value,
        }];
    }

    return filter.value.map((val, index) => ({
        condition: index === 0 ? AND : OR,
        field: combinedField,
        operator: filter.operator,
        value: val,
    }));
};

export const transformLocationFilter = (key, filter) => {
    if (!filter.value) {
        return [];
    }
    // So if key is not same as field, means we have nested condition
    const combinedField =
        key !== filter.field ? `${ key }.${ filter.field }` : filter.field;

    return {
        field: combinedField,
        value: filter.value,
        ...(filter.label ? { label: filter.label } : {}), // This is just to help us display in search details nothing to do with backend
    };
};

/**
 * Transforme Filters into Final elasticsearch payload
 * @param {*} state
 * @param {*} activeTab
 * @returns
 */
export const transformStateToPayload = (
    state,
    locations,
    activeTab,
    searchQuery,
    page,
    size,
    sorting,
    currentLanguage,
    defaultFilter = null
) => {
    const filterMappings = TAB_MAPPINGS[activeTab];
    if (!filterMappings) {
        throw new Error(`Unsupported tab: ${ activeTab }`);
    }
    const priorityOrder = ['age_groups', 'illnesses'];

    const filters = Object.entries(filterMappings).map(([key, documentType]) => {
        const filterObj = state[key] || {};
        const locationObject = locations[key] || {};
        // This block of code transforms the filters for the Elasticsearch query.
        // It uses the Object.entries() method to return an array of a given object's own enumerable string-keyed property [key, value] pairs.
        // The flatMap() method is used to first map each element using a mapping function, then flattens the result into a new array.
        // It's essentially a map() method followed by a flat() of depth 1, but flatMap() is often quite useful, as merging both into one method is slightly more efficient.
        const transformedFilters = Object.entries(filterObj).sort((a, b) => {
            // Check if a or b are in the priorityOrder array
            const aIndex = priorityOrder.indexOf(a[0]);
            const bIndex = priorityOrder.indexOf(b[0]);

            // If both a and b are in the priority list, sort based on their order in the array
            if (aIndex !== -1 && bIndex !== -1) {
                return aIndex - bIndex;
            }

            // If only a is in the priority list, it should come first
            if (aIndex !== -1) {
                return -1;
            }

            // If only b is in the priority list, it should come first
            if (bIndex !== -1) {
                return 1;
            }

            // If neither is in the priority list, sort alphabetically
            return a[0].localeCompare(b[0]);
        }).flatMap(
            // For each [key, value] pair in the filterObj object, the transformFilter function is called.
            // The key is the subKey and the value is the filter.
            ([subKey, filter]) => transformFilter(subKey, filter)
        );
        if (transformedFilters.length > 1) {
            for (let i = 1; i < transformedFilters.length; i++) {
                const condition = transformedFilters[i].condition;
                if (condition === "and") {
                    const currentField = transformedFilters[i].field.split('_')[0];
                    const previousField = transformedFilters[i - 1].field.split('_')[0];
                    if (currentField === previousField) {
                        transformedFilters[i].condition = "or";
                    }
                }
            }
        }
        const idx = transformedFilters.findIndex(
            obj => obj.condition === "and" && obj.field !== "illnesses.id" && obj.field !== "age_groups.id"
        );
        for (let i = idx + 1; i < transformedFilters.length; i++) {
            if (transformedFilters[i].condition === "and") {
                transformedFilters[i].condition = "or";
            }
        }

        const transformedLocations = Object.entries(locationObject).flatMap(
            ([subKey, loc]) => transformLocationFilter(subKey, loc)
        );
        if (defaultFilter) {
            transformedFilters.push(defaultFilter);
        }

        const baseFilter = {
            [documentType]: {
                conditions: transformedFilters,
                location: transformedLocations,
                aggregations: AGGREGATE_FILTERS,
            },
        };
        if (sorting === ORDER_ALPHABETICALLY) {
            baseFilter[documentType].sort = {
                [`${ DOCUMENT_SORTING_COLUMNS[documentType] }_${ currentLanguage }.sorting`]:
                    {
                        order: "asc",
                    },
            };
        } else if (typeof sorting === "object" && "_geo_distance" in sorting) {
            baseFilter[documentType].sort = sorting;
        }
        return baseFilter;
    });

    return {
        query: searchQuery,
        page: page,
        size: size,
        filters: filters,
        // aggregations: AGGREGATE_FILTERS,
    };
};

/**
 * Base filters
 */
export const baseFilters = [
    {
        [NEWS_ELASTICSEARCH_DOCUMENT]: {
            conditions: [],
        },
    },
    {
        [DOCUMENTS_ELASTICSEARCH_DOCUMENT]: {
            conditions: [],
        },
    },
    {
        [GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT]: {
            conditions: [],
        },
    },
    {
        [RESOURCE_ELASTICSEARCH_DOCUMENT]: {
            conditions: [],
            location: [],
        },
    },
];

/**
 * Add debounce on actions
 *
 * @param {*} func
 * @param {*} wait
 * @returns
 */
export const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

/**
 * Get excerpt from long text
 *
 * @param {*} text
 * @param {*} maxLength
 * @returns
 */
export const getExcerpt = (text, maxLength = 100) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength - 3) + "...";
};

/**
 *
 * @param status
 * @param msg
 * @returns {string}
 */
export const setErrorMessage = (status, msg = "") => {
    const errorMessages = {
        404: "error.error_page_404",
        401: "error.error_page_401",
        403: "error.error_page_403",
        500: "Unexpected Error ):",
        422: "error.error_page_422",
        405: msg,
        409: msg,
        400: msg,
        402: msg,
    };

    return errorMessages[status] || "error.unexpected_error";
};

export const getGoogleMapsLink = (address) => {
    const base_url = "https://www.google.com/maps";
    const query = `?q=${ address }`;
    return base_url + query;
};

/**
 * Add aggregation to filters
 */
export const addAggregationToFilter = (items, aggregates) => {
    const aggregate = aggregates[0];
    const lookup = aggregate.reduce((acc, curr) => {
        acc[curr.id] = curr.count;
        return acc;
    }, {});

    return items.map((item) => {
        if ("children" in item) {
            return ({
                ...item,
                children: addAggregationToFilter(item.children, aggregates.slice(1)),
                count: lookup[item.id] || 0,
            });
        } else {
            return ({
                ...item,
                count: lookup[item.id] || 0,
            });
        }
    });
};


export const fetchPostalCode = async (latitude, longitude) => {
    // eslint-disable-next-line no-undef
    const apiUrl = `/api/reverse-geo-code?lat=${ latitude }&lng=${ longitude }`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.length > 0) {
            for (const component of data[0].address_components) {
                if (component.types.includes('postal_code')) {
                    const postalCode = component.short_name.replace(/\s/g, "");
                    // Fetch data based on the postal code
                    const postalResponse = await fetch(`/api/postal-code/?q=${ postalCode }`);
                    const postalData = await postalResponse.json();
                    return postalData.results[0];
                }
            }
        } else {
            console.error('No results found');
            return null;
        }
    } catch (error) {
        console.error('Error fetching postal code:', error);
        return null;
    }
};


export const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getLKey = (key) => key + "_" + window.languageCode || 'fr';

export const getLocationObject = (selected) => {
    return {
        code: {
            field: "id",
            value: selected.id,
            label: `${ selected.city } (${ selected.code })`,
        },
        city: { field: "id", value: selected.cityId },
        district: { field: "id", value: selected.districtId },
        mrc: { field: "id", value: selected.mrcId },
        rls: { field: "id", value: selected.rlsId },
        ciusss: { field: "id", value: selected.ciusssId },
        region: { field: "id", value: selected.regionId },
    };
};

export const getCoordinatesFromPostalCode = async (postalCode) => {
    // eslint-disable-next-line no-undef
    const apiUrl = `/api/geo-code?address=${ postalCode }`;
    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.length > 0) {
            const coordinates = data[0].geometry.location;
            console.log('Coordinates:', coordinates);
            return {
                lat: coordinates.lat,
                lon: coordinates.lng,
            };
        }
    } catch (error) {
        console.log('Error fetching postal code:', error);
        return null;
    }
};

export const formatPhoneNumber = (phoneNumberString) => {
    try {
        // Remove all non-numeric characters except the leading "+"
        const cleaned = phoneNumberString.replace(/[^\d+]/g, '');

        // Remove the leading "+" and country code if it's there
        let normalized = cleaned.startsWith('+1') ? cleaned.slice(2) : cleaned;

        // If the cleaned string has 10 digits, format it
        const match = normalized.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `${ match[1] }-${ match[2] }-${ match[3] }`;
        }

        return phoneNumberString;
    } catch (e) {
        return "";
    }
};
